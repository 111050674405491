import { lazy } from "react";
import { v4 as uuidv4 } from "uuid";

import { getClientConfig } from '../../helpers/portal/getClientConfig';
import { SYSTEM_TIZEN, SYSTEM_WEB, SYSTEM_WEBOS } from '../../constants/system';

const devicesType = getClientConfig().devicesType;


const ExitTizenApp = (devicesType === SYSTEM_TIZEN)
  ? lazy(() => import('../../components-spacial/ExitApp/ExitApp'))
  : () => NotImplemented()
const ApplicationLoader = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  // ? lazy(() => import('../../components-tv/AplicationLoader'))
  ? lazy(() => import('../../components-spacial/Share/LoadingSpinner/AppLoader'))
  : lazy(() => import('../../components/AplicationLoader'));
const LoginWithToken = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? () => NotImplemented()
  : lazy(() => import('../../components/Customer/LoginWithToken'));
const Category = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  // ? lazy(() => import('../../components-tv/Category'))
  ? lazy(() => import('../../components-spacial/DetailView/CategoryDetailView'))
  : lazy(() => import('../../components/Category'));
const Channels = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  // ? lazy(() => import('../../components-tv/Channels/TvChannels'))
  ? lazy(() => import('../../components-spacial/Channels/Channels'))
  : () => NotImplemented();
const RadioChannels = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  // ? lazy(() => import('../../components-tv/Channels/RadioChannels'))
  ? lazy(() => import('../../components-spacial/Channels/Radios'))
  : () => NotImplemented();
const Epg = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  // ? lazy(() => import('../../components-tv/Epg'))
  ? lazy(() => import('../../components-spacial/TVGuide/TVGuide'))
  : lazy(() => import('../../components/Epg'));
const EpgItem = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  // ? lazy(() => import('../../components-tv/Epg/EpgItem'))
  ? lazy(() => import('../../components-spacial/DetailView/EventDetailView'))
  : lazy(() => import('../../components/Epg/EpgItem'));
const Hp = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? lazy(() => import('../../components-spacial/Homepage/Homepage'))
  : lazy(() => import('../../components/HP'));
const Library = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  // ? lazy(() => import('../../components-tv/Recordings/Library'))
  ? lazy(() => import('../../components-spacial/Vod/Vod'))
  : lazy(() => import('../../components/Vod'));
const Login = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? lazy(() => import('../../components-spacial/Login/Login'))
  : lazy(() => import('../../components/Customer/Login'));
const LoginQr = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? lazy(() => import('../../components-spacial/Login/LoginQr'))
  : () => NotImplemented();
const SelectProvider = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? lazy(() => import('../../components-spacial/Login/Provider'))
  : () => NotImplemented();
const CustomerCreate = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? () => NotImplemented()
  : lazy(() => import('../../components/Customer/CustomerCreate'));
const ChangePasswordWithPin = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? () => NotImplemented()
  : lazy(() => import('../../components/Customer/ChangePasswordWithPin'));
const ChangePassword = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? () => NotImplemented()
  : lazy(() => import('../../components/Customer/ChangePassword'));
const CustomerConfirmation = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? () => NotImplemented()
  : lazy(() => import('../../components/Customer/CustomerConfirmation'));
const LostPassword = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? () => NotImplemented()
  : lazy(() => import('../../components/Customer/LostPassword'));
const CustomerForceChangePassword = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? () => NotImplemented()
  : lazy(() => import('../../components/Customer/CustomerForceChangePassword'));
const Recordings = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  // ? lazy(() => import('../../components-tv/Recordings'))
  ? lazy(() => import('../../components-spacial/Recordings/Recordings'))
  : lazy(() => import('../../components/Recordings'));
const RecordingDetail = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  // ? lazy(() => import('../../components-tv/Recordings/RecordingDetail'))
  ? lazy(() => import('../../components-spacial/DetailView/RecordingDetailView'))
  : lazy(() => import('../../components/Recordings/RecordingDetail'));
const RecordingsPlayer = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  // ? lazy(() => import('../../components-tv/Recordings/RecordingsPlayer'))
  ? lazy(() => import('../../components-spacial/Player/PlayerRecording'))
  : lazy(() => import('../../components/Recordings/RecordingPlayer'));
const SelectProfile = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? lazy(() => import('../../components-spacial/Login/SelectProfile'))
  : lazy(() => import('../../components/Customer/SelectProfile'));
const Settings = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  // ? lazy(() => import('../../components-tv/Settings'))
  ? lazy(() => import('../../components-spacial/Settings/Settings'))
  : lazy(() => import('../../components/Settings'));
const SettingsForBasicUser = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  // ? lazy(() => import('../../components-tv/Settings'))
  ? lazy(() => import('../../components-spacial/Settings/Settings'))
  : lazy(() => import('../../components/Settings/SettingsForBasicUser'));
const TV = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  // ? lazy(() => import('../../components-spacial/Player/DemoPlayer'))
  // ? lazy(() => import('../../components-tv/FullPlayer/TV'))
  ? lazy(() => import('../../components-spacial/Player/Player'))
  : lazy(() => import('../../components/Tv'));
const Radio = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  // ? lazy(() => import('../../components-tv/FullPlayer/Radio'))
  ? lazy(() => import('../../components-spacial/Player/Player'))
  : lazy(() => import('../../components/Radio'));
const VodDetail = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  // ? lazy(() => import('../../components-tv/Recordings/VodDetail'))
  ? lazy(() => import('../../components-spacial/DetailView/VodDetailView'))
  : lazy(() => import('../../components/Vod/DialogVodOpenFromUrl'));
const VodPlayer = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  // ? lazy(() => import('../../components-tv/Vod/VodPlayer'))
  ? lazy(() => import('../../components-spacial/Player/PlayerVod'))
  : lazy(() => import('../../components/VodPlayer'));
const Search = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? () => NotImplemented()
  : lazy(() => import('../../components/Search'));
const Error404 = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? () => NotImplemented()
  : lazy(() => import('../../components/Error404'));
const Person = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? () => NotImplemented()
  : lazy(() => import('../../components/Person'));
const CustomPage = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? () => NotImplemented()
  : lazy(() => import('../../components/CustomPage'));
const Notification = (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS)
  ? () => NotImplemented()
  : lazy(() => import('../../components/Notification'));


export const allRouters = [
  {
    name: "ExitApp",
    id: uuidv4(),
    component: ExitTizenApp,
    useIt: ({exitTizenApp}) => exitTizenApp,
  },
  {
    name: "ApplicationLoader",
    id: uuidv4(),
    component: ApplicationLoader,
    useIt: ({applicationIsLoaded}) => !applicationIsLoaded,
  },
  {
    name: "CustomPage",
    id: uuidv4(),
    component: CustomPage,
    path: ["/info/:pageName"],
  },
  {
    name: "LoginWithToken",
    id: uuidv4(),
    component: LoginWithToken,
    path: ["/login/with-token/:token/:preferedProfileId/:profilePin", "/login/with-token/:token/:preferedProfileId", "/login/with-token/:token"],
  },
  {
    name: "CustomerCreate",
    id: uuidv4(),
    component: CustomerCreate,
    path: "/customer/create",
  },
  {
    name: "ChangePasswordWithPin",
    id: uuidv4(),
    component: ChangePasswordWithPin,
    path: "/login/change-password-with-pin/:login",
  },
  {
    name: "ChangePassword",
    id: uuidv4(),
    component: ChangePassword,
    path: "/login/change-password/:token",
  },
  {
    name: "Channels",
    id: uuidv4(),
    component: Channels,
    path: "/channels",
  },
  {
    name: "Radio channels",
    id: uuidv4(),
    component: RadioChannels,
    path: "/radio/channels",
  },
  {
    name: "LostPassword",
    id: uuidv4(),
    component: LostPassword,
    path: "/login/lost-password",
    useIt: ({forgotPasswordAllowed}) => forgotPasswordAllowed,
  },
  {
    name: "CustomerConfirmation",
    id: uuidv4(),
    component: CustomerConfirmation,
    path: "/login/confirmation/:token",
  },
  {
    name: "SelectProvider",
    id: uuidv4(),
    component: SelectProvider,
    path: "/select-provider",
    useIt: ({isLogged}) => !isLogged,
  },
  {
    name: "LoginQr",
    id: uuidv4(),
    component: LoginQr,
    path: "/login/login-qr",
    useIt: ({isLogged}) => !isLogged,
  },
  {
    name: "RegisterSocial",
    id: uuidv4(),
    component: CustomerCreate,
    useIt: ({isLogged, isRegistrationFinished}) => isLogged && !isRegistrationFinished,
  },
  {
    name: "Login",
    id: uuidv4(),
    component: Login,
    useIt: ({isLogged}) => !isLogged,
  },
  {
    name: "CustomerForceChangePassword",
    id: uuidv4(),
    component: CustomerForceChangePassword,
    useIt: ({isLogged}) => isLogged,
    path: "/customer/force-change-password",
  },
  {
    name: "SelectProfile",
    id: uuidv4(),
    component: SelectProfile,
    useIt: ({isLogged, profileId}) => isLogged && profileId === undefined,
    path: ["/select-profile/:preferedProfileId(\d+)", "/select-profile"],
  },
  {
    name: "SelectProfile",
    id: uuidv4(),
    component: SelectProfile,
    useIt: ({isLogged, profileId}) => isLogged && profileId === undefined,
  },
  {
    name: "TV",
    id: uuidv4(),
    component: TV,
    useIt: ({isLogged, profileId, motv_portals_sections}) => isLogged && profileId !== undefined && motv_portals_sections?.live,
    path: ["/tv/player/:channels_id/:timestamp", "/tv/player/:channels_id", "/tv/player"],
  },
  {
    name: "Radio",
    id: uuidv4(),
    component: Radio,
    useIt: ({isLogged, profileId, motv_portals_sections}) => isLogged && profileId !== undefined && motv_portals_sections?.radio,
    path: ["/radio/player/:channels_id/:timestamp", "/radio/player/:channels_id", "/radio/player", "/radio"],
  },
  {
    name: "VodPlayer",
    id: uuidv4(),
    component: VodPlayer,
    useIt: ({isLogged, profileId, motv_portals_sections}) => isLogged && profileId !== undefined && motv_portals_sections?.vod,
    path: "/vod/player/:id",
  },
  {
    name: "VodDetail",
    id: uuidv4(),
    component: VodDetail,
    useIt: ({isLogged, profileId}) => isLogged && profileId !== undefined,
    path: "/vod/detail/:id",
  },
  {
    name: "VodDetail",
    id: uuidv4(),
    component: VodDetail,
    useIt: ({isLogged, profileId}) => isLogged && profileId !== undefined,
    path: "/vod/:id",
  },
  {
    name: "Library",
    id: uuidv4(),
    component: Library,
    useIt: ({isLogged, profileId, motv_portals_sections}) => isLogged && profileId !== undefined && motv_portals_sections?.vod,
    path: "/vod",
  },
  {
    name: "Person",
    id: uuidv4(),
    component: Person,
    useIt: ({isLogged, profileId, motv_portals_sections}) => isLogged && profileId !== undefined && motv_portals_sections?.vod,
    path: "/person/:id",
  },
  {
    name: "EpgItem",
    id: uuidv4(),
    component: EpgItem,
    useIt: ({isLogged, profileId}) => isLogged && profileId !== undefined,
    path: "/event/detail/:id"
  },
  {
    name: "Epg",
    id: uuidv4(),
    component: Epg,
    useIt: ({isLogged, profileId, motv_portals_sections}) => isLogged && profileId !== undefined && motv_portals_sections?.live,
    path: "/epg",
  },
  {
    name: "RecordingsPlayer",
    id: uuidv4(),
    component: RecordingsPlayer,
    useIt: ({isLogged, profileId, motv_portals_sections}) => isLogged && profileId !== undefined && motv_portals_sections?.recordings,
    path: "/recording/player/:id",
  },
  {
    name: "RecordingDetail",
    id: uuidv4(),
    component: RecordingDetail,
    useIt: ({isLogged, profileId, motv_portals_sections}) => isLogged && profileId !== undefined && motv_portals_sections?.recordings,
    path: "/recordings/:id",
  },
  {
    name: "Recordings",
    id: uuidv4(),
    component: Recordings,
    useIt: ({isLogged, profileId, motv_portals_sections}) => isLogged && profileId !== undefined && motv_portals_sections?.recordings,
    path: "/recordings",
  },
  {
    name: "Settings",
    id: uuidv4(),
    component: Settings,
    useIt: ({isLogged, profileId, devicesType}) => isLogged && profileId !== undefined && (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS),
    path: "/settings",
  },
  {
    name: "Settings",
    id: uuidv4(),
    component: Settings,
    useIt: ({isLogged, profileId, devicesType}) => isLogged && profileId !== undefined && (devicesType === SYSTEM_TIZEN || devicesType === SYSTEM_WEBOS),
    path: "/settings",
  },
  {
    name: "Settings",
    id: uuidv4(),
    component: Settings,
    useIt: ({isLogged, profileId, isAdmin, devicesType}) => isLogged && profileId !== undefined && isAdmin && devicesType === SYSTEM_WEB,
    path: ["/settings/:section/:subSection/:id(\d+)", "/settings/:section/:subSection", "/settings/:section", "/settings"],
  },
  {
    name: "SettingsForBasicUser",
    id: uuidv4(),
    component: SettingsForBasicUser,
    useIt: ({isLogged, profileId, devicesType}) => isLogged && profileId !== undefined  && devicesType === SYSTEM_WEB,
    path: ["/settings"],
  },
  { // Identické s "Category", musel jsem přidat kvůli deeplinkům z reklamních bannerů, protože Ondrovy aplikace používají formát /category/detail/:id a je potřeba, aby deeplinky fungovali z této adresy všude
    name: "CategoryDetail",
    id: uuidv4(),
    component: Category,
    useIt: ({isLogged, profileId, motv_portals_sections}) => isLogged && profileId !== undefined && motv_portals_sections?.vod,
    path: "/category/detail/:id",
  },
  {
    name: "Category",
    id: uuidv4(),
    component: Category,
    useIt: ({isLogged, profileId, motv_portals_sections}) => isLogged && profileId !== undefined && motv_portals_sections?.vod,
    path: "/category/:id",
  },
  {
    name: "Search",
    id: uuidv4(),
    component: Search,
    useIt: ({isLogged, profileId}) => isLogged && profileId !== undefined,
    path: "/search/:query",
  },
  {
    name: "Notification",
    id: uuidv4(),
    component: Notification,
    useIt: ({isLogged, profileId}) => isLogged && profileId !== undefined,
    path: "/notification",
  },
  {
    name: "Hp",
    id: uuidv4(),
    component: Hp,
    useIt: ({isLogged, profileId, motv_portals_sections}) => isLogged && profileId !== undefined && motv_portals_sections?.homepage,
  },
  {
    name: "Error404",
    id: uuidv4(),
    component: Error404,
  },
];


const NotImplemented = () => {
  return <div style={{
      backgroundColor: "black",
      color: "white",
      fontSize: "24px",
    }}>Not implemented</div>;
};
