export const setClientStorage = (name, value) => {
  window.localStorage.setItem(name, value);
};

export const getClientStorage = (name) => {
  return window.localStorage.getItem(name) !== "undefined" ? window.localStorage.getItem(name) : undefined;
};

export const removeClientStorage = (name) => {
  window.localStorage.setItem(name, undefined);
};
