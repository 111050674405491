import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from "react";

import { allRouters } from './allRouters';
import { getClientConfig } from '../../helpers/portal/getClientConfig';
import { getClientStorage } from '../../helpers/device/clientStorage';
import { useSelector } from "react-redux";

const devicesType = getClientConfig().devicesType;

export const useRouters = () => {

  const location = useLocation();

  // TODO: EDGE Speedcheck motvSpeedCheck zprovoznit
  const allItemsHasFinishedSpeedTest = useSelector(state => state.edge.allItemsHasFinishedSpeedTest);
  const customers_profiles_id = useSelector(state => state.user.customers_profiles_id);
  const dataGenerationOfUser = useSelector(state => state.user.dataGeneration);
  const customers_token = useSelector(state => state.user.customers_token);
  const deviceHash = useSelector(state => state.portal.deviceHash);
  const deviceInfo = useSelector(state => state.portal.deviceInfo);
  const motv_portals_account_update_enabled = useSelector(state => state.portal.motv_portals_account_update_enabled);
  const motv_portals_custom_registration_open = useSelector(state => state.portal.motv_portals_custom_registration_open);
  const motv_portals_id = useSelector(state => state.portal.motv_portals_id);
  const motv_portals_sections = useSelector(state => state.portal.motv_portals_sections);
  const profileId = useSelector(state => state.user?.selectedProfile?.profiles_id);
  const vendors_id = useSelector(state => state.vendor.vendors_id);
  const device_motv_social_registration_completed = useSelector(state => state.user.device_motv_social_registration_completed)
  const universal_exitTizenApp = useSelector(state => state.universal.exitTizenApp)
  const motv_portals_lost_password_enabled =  useSelector(state => state.portal.motv_portals_lost_password_enabled)

  const [usedRouters, setUsedRouters] = useState([]);

  const isLogged = useMemo(() => {
    return !!(
      customers_token &&
      getClientStorage('authorization')
    );
    }, [dataGenerationOfUser, customers_token, location.pathname]);

  const isRegistrationFinished = useMemo(() => {
        return !!device_motv_social_registration_completed
        }, [dataGenerationOfUser, customers_token, location.pathname, device_motv_social_registration_completed]);

  const forgotPasswordAllowed = useMemo(() => {
    return !!motv_portals_lost_password_enabled
  }, [motv_portals_lost_password_enabled]);

  const exitTizenApp = useMemo(() => {
    return !!universal_exitTizenApp
  }, [universal_exitTizenApp]);

  const applicationIsLoaded = (
    motv_portals_id !== undefined
    && deviceInfo !== undefined
    && deviceHash !== undefined
    // TODO: EDGE Speedcheck motvSpeedCheck zprovoznit
    // && allItemsHasFinishedSpeedTest
    && vendors_id !== undefined
  );

  const isAdmin = useMemo(() => {
    return !!(customers_profiles_id &&
      customers_profiles_id === profileId);
    }, [customers_profiles_id, profileId]);


  const watcherVariables = [
    applicationIsLoaded,
    customers_token,
    dataGenerationOfUser,
    devicesType,
    isAdmin,
    isLogged,
    isRegistrationFinished,
    motv_portals_account_update_enabled,
    motv_portals_custom_registration_open, // vlastní registrace
    motv_portals_sections, // na jaké sekce má uživatel práva
    profileId,
    exitTizenApp,
  ];

  const routers = useMemo(() => (allRouters), [...watcherVariables]);


  useEffect(() => {
    setUsedRouters(() => routers.filter(routeItem =>
      !!(!routeItem.useIt || routeItem.useIt({
        applicationIsLoaded,
        forgotPasswordAllowed,
        isAdmin,
        isRegistrationFinished,
        isLogged,
        motv_portals_account_update_enabled,
        motv_portals_custom_registration_open, // vlastní registrace
        motv_portals_sections, // na jaké sekce má uživatel práva
        profileId,
        devicesType,
        exitTizenApp,
      }))
    ));
  }, [...watcherVariables]);

  return usedRouters;
};
