const config = {devicesType: "web player",
    clientName: "TVN SLZ",
    portalId: 1,
    vendorId: 1,
    theme: {}, // option, nastavení tématu
    avatarImages: [
        "/assets/profile-images/i1.png",
        "/assets/profile-images/i2.png",
        "/assets/profile-images/i3.png",
        "/assets/profile-images/i4.png",
        "/assets/profile-images/i5.png",
        "/assets/profile-images/i6.png",
        "/assets/profile-images/i7.png",
        "/assets/profile-images/i8.png",
        "/assets/profile-images/i9.png",
    ], // option, cesty k předvolenými avatar obrázky
    mwUrl: "https://mwslz.tvn.com.br", // require, cesta na middleware (https://mw.cico.tv)
    smsUrl: "https://smsslz.tvn.com.br",
    languages: [ "pt", "en" ],
    errorReloadTime: 1000 * 10, // every ten second we try remove errors
}

exports.config = config;