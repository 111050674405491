import {
  edges_init,
  sms_devices_getPortal,
  vendor_getData,
} from '../actions';
import { actionTypes as types } from '../constants';

import Fingerprint2 from 'fingerprintjs2sync';
import UAParser from 'ua-parser-js';
import { getClientConfig } from '../helpers/portal/getClientConfig';

export const portal_setParam = (param) => async(dispatch) => {
  await dispatch({
    type: types.PORTAL_SET_PARAM,
    param: param,
  });
};


export const portal_loadingApp = (tryIterationCounter = 0) => async(dispatch, getState) => {
  if(tryIterationCounter >= 10) {
    dispatch({type: types.PORTAL_LOADING_APP_FAILURE});
    return;
  }

  dispatch({type: types.PORTAL_LOADING_APP_REQUEST});
  await Promise.all([
    // TODO: EDGE Speedcheck motvSpeedCheck zprovoznit, také řádek 40, také useRouters/index.js
    // dispatch(edges_init()),
    dispatch(portal_setDeviceInfo()),
    dispatch(vendor_getData({vendorsId: getClientConfig().vendorId})),
    dispatch(sms_devices_getPortal({motvPortalsId: getClientConfig().portalId})),
  ]);

  // result happy/unhappy
  const applicationIsLoaded = !!(
    getState().portal.motv_portals_id !== undefined
    && getState().portal.deviceInfo !== undefined
    && getState().portal.deviceHash !== undefined
    // TODO: EDGE Speedcheck motvSpeedCheck zprovoznit
    // && getState().edge.allItemsHasFinishedSpeedTest
    && getState().vendor.vendors_id !== undefined
  );

  if(!applicationIsLoaded) {
    dispatch(portal_loadingApp(tryIterationCounter + 1));
    return;
  }

  dispatch({type: types.PORTAL_LOADING_APP_SUCCESS});
};

const portal_setDeviceInfo = () => async(dispatch) => {
  const uaParser = new UAParser();
  const browser = uaParser.getBrowser();
  const os = uaParser.getOS();
  await dispatch(portal_setParam({
    deviceInfo: `${os['name']} ${os['version']} ${browser['name']} ${browser['major']}`,
    deviceHash: (new Fingerprint2()).getSync().fprint,
  }));
};
