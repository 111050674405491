import { actionTypes as types } from '../constants';
import { concatAndDeDuplicateObjects } from '../helpers/array/concatAndDeDuplicateObjects';

import { setClientStorage, getClientStorage, removeClientStorage } from '../helpers/device/clientStorage';

const defaultState = {
  applicationIsLoaded: false,
  applicationLoadedFailed: false,
  customPages: [],
  customPagesGeneration: 0,
  deviceHash: undefined,
  deviceInfo: undefined,
  gdprIsAccepted: false,
  isMobileView: undefined,
  loaded: true,
  redirectToHp: false,
  refreshPageRoles: false,
  urlRedirectAfterLogin: undefined,
};

const portal = (state = defaultState, action) => {
  switch (action.type) {
    case types.SMS__DEVICES__GET_PORTAL_REQUEST:
      return { ...state, loaded: false, refreshPageRoles: false };
    case types.SMS__DEVICES__GET_PORTAL_SUCCESS:
      return {
        ...state,
        ...action.data.response,
        loaded: true,
        refreshPageRoles: true,
        gdprIsAccepted:
          (
            !action.data.response.motv_portals_web_player_cookie === undefined
            || action.data.response.motv_portals_web_player_cookie === 0
            || getClientStorage("gdprAccepted") === "true"
          ),
        motv_portals_player_theme: {
          material: {

          },
          logo: {
            "border": "1px solid gold",
          }
        }
      };
    case types.SMS__DEVICES__GET_PORTAL_FAILURE:
      return { ...state, loaded: true };

    case types.SMS__DEVICES__GET_PORTAL_PAGE_BY_NAME_REQUEST:
      return { ...state, loaded: false };
    case types.SMS__DEVICES__GET_PORTAL_PAGE_BY_NAME_SUCCESS:
      return {
        ...state,
        customPages: concatAndDeDuplicateObjects(
          'motv_portals_pages_id',
          state.customPages,
          [action.data.response],
        ),
        customPagesGeneration: state.customPagesGeneration + 1,
        loaded: true,
      };
    case types.SMS__DEVICES__GET_PORTAL_PAGE_BY_NAME_FAILURE:
      return { ...state, loaded: true };

    case types.PORTAL_SET_PARAM:
      return { ...state, ...action.param };

    case types.PORTAL_LOADING_APP_REQUEST:
      return {...state, loaded: false};
    case types.PORTAL_LOADING_APP_SUCCESS:
      return {...state, loaded: true,
        applicationIsLoaded: true,
      };
    case types.PORTAL_LOADING_APP_FAILURE:
      return {...state, loaded: true,
        applicationIsLoaded: false,
        applicationLoadedFailed: true,
      };

    case types.USER_SET_SELECTED_PROFILE_SUCCESS:
      return {...state,
        urlRedirectAfterLogin:
          action.urlRedirectAfterLogin || state.urlRedirectAfterLogin,
      };

    case types.CUSTOMER_LOGIN_SUCCESS:
      return {...state,
        urlRedirectAfterLogin:
          action.urlRedirectAfterLogin || state.urlRedirectAfterLogin,
      };

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return {...defaultState,
        redirectToHp: true,
      };

    case types.RESET_STATE:
      return {...defaultState,
        gdprIsAccepted: state.gdprIsAccepted,
      };
    default:
      return state;
  }
};

export default portal;
