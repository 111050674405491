import {
  fetch_epg_getUpdatedEventsV2,
  fetch_epg_getUpdatedEventsV2WithoutRedux,
  fetch_recommendation_getMyList,
  fetch_recording_getDataV2,
  fetch_recording_getRecordingsByProfile,
  fetch_vod_getDataV2,
} from "../fetchs";
import getExtendedUniversalItem from "../helpers/universal/getExtendedUniversalItem";
import { actionTypes as types, contentTypes } from "../constants";
import { isSuccessResponse } from "../helpers/fetch/isSuccessResponse";
import { getExtendEpgItem } from "../helpers/epg/getExtendEpgItem";
import { rowsDataInFlatView } from "../helpers/recommendation/rowsDataInFlatView";

export const universal_getSelectedItem =
  (id, contentType) => async (dispatch) => {
    dispatch({ type: types.UNIVERSAL_GET_SELECTED_ITEM_REQUEST });
    const itemFetch =
      contentType === contentTypes.VOD_CONTENT_TYPE
        ? await dispatch(fetch_vod_getDataV2({ vodsId: id }))
        : contentType === contentTypes.RECORDING_CONTENT_TYPE
        ? await dispatch(fetch_recording_getDataV2({ epgEventsId: id }))
        : contentType === contentTypes.TV_CONTENT_TYPE
        ? await dispatch(
            fetch_epg_getUpdatedEventsV2WithoutRedux({
              ids: [id],
              timestamp: 0,
            })
          )
        : {};
    const myListFetch = await dispatch(fetch_recommendation_getMyList());
    const recordingsFetch = await dispatch(
      fetch_recording_getRecordingsByProfile()
    );
    if (
      !isSuccessResponse(itemFetch) ||
      !isSuccessResponse(myListFetch) ||
      !isSuccessResponse(recordingsFetch)
    ) {
      dispatch({ type: types.UNIVERSAL_GET_SELECTED_ITEM_FAILURE });
      return;
    }
    const itemResponse = itemFetch.response.length
      ? itemFetch.response[0]
      : itemFetch.response;
    const myListResponse = myListFetch.response;
    const recordingsResponse = recordingsFetch.response;
    const extendedUniversalItem = getExtendedUniversalItem(itemResponse, {
      myListItems: myListResponse,
      recordingItems: recordingsResponse,
    });
    dispatch({
      type: types.UNIVERSAL_GET_SELECTED_ITEM_SUCCESS,
      selectedItem: extendedUniversalItem,
    });
  };

export const universal_loadUniversalDetailDialog =
  (boxItem) => (dispatch, getState) => {
    dispatch({
      type: types.UNIVERSAL_LOAD_UNIVERSAL_DETAIL_DIALOG_REQUEST,
      param: { boxItem },
    });

    Promise.all([
      dispatch(
        fetch_epg_getUpdatedEventsV2({ ids: [boxItem.id], timestamp: 0 })
      ),
      dispatch(fetch_recording_getRecordingsByProfile()),
      dispatch(fetch_recommendation_getMyList()),
    ]).then(
      ([eventsResponse, recordingsResponse, myListResponse]) => {
        if (
          !isSuccessResponse(eventsResponse) ||
          !isSuccessResponse(recordingsResponse) ||
          !isSuccessResponse(myListResponse)
        ) {
          dispatch({
            type: types.UNIVERSAL_LOAD_UNIVERSAL_DETAIL_DIALOG_FAILURE,
            param: { boxItem },
          });
          return;
        }

        const extendedUniversalItem = getExtendEpgItem(boxItem, {
          recordingItems: rowsDataInFlatView(recordingsResponse.response),
          myListItems: myListResponse.response,
          selectedItemInState: getState().epg.selectedItem,
        });

        dispatch({
          type: types.UNIVERSAL_LOAD_UNIVERSAL_DETAIL_DIALOG_SUCCESS,
          param: { extendedUniversalItem },
        });
      },
      (error) => {
        dispatch({
          type: types.UNIVERSAL_LOAD_UNIVERSAL_DETAIL_DIALOG_FAILURE,
          param: { boxItem },
        });
        console.error({ error });
      }
    );
  };

export const universal_unloadUniversalDetailDialog = (dispatch) => {
  dispatch({ type: types.UNIVERSAL_UNLOAD_UNIVERSAL_DETAIL_DIALOG });
};

export const universal_exitTizenAppDialogTrue = (dispatch) => {
  dispatch({ type: types.UNIVERSAL_EXIT_TIZEN_APP_DIALOG_TRUE });
}

export const universal_exitTizenAppDialogFalse = (dispatch) => {
  dispatch({ type: types.UNIVERSAL_EXIT_TIZEN_APP_DIALOG_FALSE });
}
