export const UNIVERSAL_GET_SELECTED_ITEM_REQUEST = "UNIVERSAL_GET_SELECTED_ITEM_REQUEST";
export const UNIVERSAL_GET_SELECTED_ITEM_SUCCESS = "UNIVERSAL_GET_SELECTED_ITEM_SUCCESS";
export const UNIVERSAL_GET_SELECTED_ITEM_FAILURE = "UNIVERSAL_GET_SELECTED_ITEM_FAILURE";

export const UNIVERSAL_LOAD_UNIVERSAL_DETAIL_DIALOG_REQUEST = "UNIVERSAL_LOAD_UNIVERSAL_DETAIL_DIALOG_REQUEST"
export const UNIVERSAL_LOAD_UNIVERSAL_DETAIL_DIALOG_SUCCESS = "UNIVERSAL_LOAD_UNIVERSAL_DETAIL_DIALOG_SUCCESS"
export const UNIVERSAL_LOAD_UNIVERSAL_DETAIL_DIALOG_FAILURE = "UNIVERSAL_LOAD_UNIVERSAL_DETAIL_DIALOG_FAILURE"

export const UNIVERSAL_UNLOAD_UNIVERSAL_DETAIL_DIALOG = "UNIVERSAL_UNLOAD_UNIVERSAL_DETAIL_DIALOG"

export const UNIVERSAL_EXIT_TIZEN_APP_DIALOG_TRUE = "UNIVERSAL_EXIT_TIZEN_APP_DIALOG_TRUE"
export const UNIVERSAL_EXIT_TIZEN_APP_DIALOG_FALSE = "UNIVERSAL_EXIT_TIZEN_APP_DIALOG_FALSE"
