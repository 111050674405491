import { actionTypes as types } from "../constants";

const defaultState = {
  loaded: true,
  selectedItem: undefined,
  detailUniversalDialog: undefined,
  detailUniversalDialogIsOpen: false,
  exitTizenApp: false,
};

const universal = (state = defaultState, action) => {
  switch (action.type) {
    case types.UNIVERSAL_GET_SELECTED_ITEM_REQUEST:
      return { ...state, loaded: false, selectedItem: undefined };
    case types.UNIVERSAL_GET_SELECTED_ITEM_SUCCESS:
      return { ...state, loaded: true, selectedItem: action.selectedItem };
    case types.UNIVERSAL_GET_SELECTED_ITEM_FAILURE:
      return { ...state, loaded: true };
    case types.PLAYER_LOAD_ONLY_SELECTED_EVENT_SUCCESS:
      return {...state,
        selectedItem: action.selectedEvent,
      }
    case types.UNIVERSAL_LOAD_UNIVERSAL_DETAIL_DIALOG_REQUEST:
      return {...state,
        detailUniversalDialog: {...action.param.epgItem, loaded: false},
        detailUniversalDialogIsOpen: true,
        loaded: false,
      }
    case types.UNIVERSAL_LOAD_UNIVERSAL_DETAIL_DIALOG_SUCCESS:
      return {...state,
        detailUniversalDialog: {...action.param.extendedUniversalItem, loaded: true},
        loaded: true,
      }
    case types.UNIVERSAL_LOAD_UNIVERSAL_DETAIL_DIALOG_FAILURE:
      return {...state,
        detailUniversalDialog: undefined,
        loaded: true,
      }
    case types.UNIVERSAL_UNLOAD_UNIVERSAL_DETAIL_DIALOG:
      return {
        ...state,
        detailUniversalDialog: undefined,
        detailUniversalDialogIsOpen: false,
        loaded: true,
      }
    case types.RECORDING_REMOVE_SUCCESS:
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          isRecordOn: false,
        },
      };
    case types.RECORDING_ADD_SUCCESS:
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          isRecordOn: true,
        },
      };
    case types.RECOMMENDATION_ADD_TO_MY_LIST_SUCCESS:
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          isInMyList: true,
        }
      }
    case types.RECOMMENDATION_REMOVE_FROM_MY_LIST_SUCCESS:
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          isInMyList: false,
        }
      }
    case types.UNIVERSAL_EXIT_TIZEN_APP_DIALOG_TRUE:
      return {
        ...state,
        exitTizenApp: true
      }
    case types.UNIVERSAL_EXIT_TIZEN_APP_DIALOG_FALSE:
      return {
        ...state,
        exitTizenApp: false
      }
    default:
      return state;
  }
};

export default universal;
