import { fullPlayerDefaultState } from '../reducers/player';
import { getClientConfig } from '../helpers/portal/getClientConfig';
import { removeAllWhiteSpace } from '../helpers/string/removeAllWhiteSpace';
import generatedPortalSettings from '../../portalSettings.json';

const ignoredStateItems = {
  error: undefined,
  network: undefined,
  tvGuide: undefined,
  // player: undefined,
  portal: undefined,
  recommendation: undefined,
  // channel: undefined, // TODO: vymazat
  // epg: undefined,
  universal: undefined,
}

const SAVE_CLIENT_NAME = removeAllWhiteSpace(getClientConfig().clientName);
const STATE_PREFIX_NAME = "state-";
const STATE_NAME = `${STATE_PREFIX_NAME}${generatedPortalSettings?.appVersion}-${SAVE_CLIENT_NAME}`;

export const saveState = state => {
  try {
    const serializedState = JSON.stringify({...state,
      ...ignoredStateItems,
    })
    localStorage.setItem(STATE_NAME, serializedState)
  } catch (err) {
    console.warn(err)
  }
}

export const loadState = () => {
  clearInvalidState();
  try {
    const serializedState = localStorage.getItem(STATE_NAME)
    if (serializedState === null) {
      return undefined
    }
    return [{...JSON.parse(serializedState),
      ...ignoredStateItems, // nejdříve vyhodíme celé kusy co nechceme znovunačítat
    }].map(stateItem => ({...stateItem, // a pak případné malé kousky state
      user: {...stateItem.user,
        warningMessage: undefined,
        registrationWasSuccessSend: undefined,
        loaded: true,
      },
      player: {...stateItem.player,
        isFullScreen: false,
        loaded: true,
        ...fullPlayerDefaultState,
      },
      channel: { ...stateItem.channel, loaded: true, selectedItem: undefined },
      epg: { ...stateItem.epg, loaded: true, selectedItem: undefined },
      confirmDialog: { ...stateItem.confirmDialog, loaded: true },
      customer: { ...stateItem.customer, loaded: true },
      device: { ...stateItem.device, loaded: true },
      edge: { ...stateItem.edge, loaded: true },
      person: { ...stateItem.person, loaded: true },
      recording: { ...stateItem.recording, loaded: true },
      search: { ...stateItem.search, loaded: true },
      subscription: { ...stateItem.subscription, loaded: true },
      vendor: { ...stateItem.vendor, loaded: true },
      universal: { ...stateItem.universal, loaded: true },
      vod: { ...stateItem.vod, loaded: true, selectedVod: undefined },
    }))[0]
  } catch (err) {
    return undefined
  }
}

/**
 * cleaning old and invalid state
 */
const clearInvalidState = () => {
  Object.keys(localStorage).forEach(function(key) {
    if(!key.startsWith(STATE_PREFIX_NAME)) return;
    if(key !== STATE_NAME) localStorage.removeItem(key);
  });
}
