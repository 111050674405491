import {
  fetch_channel_getDefaultChannel,
  fetch_channel_getStreamUrl,
  fetch_profile_addFavoriteChannel,
  fetch_profile_removeFavoriteChannel,
  fetch_epg_getUpdatedEventsV2,
  fetch_epg_getUpdatedEventsV2WithoutRedux,
} from '../fetchs';
import { actionTypes as types } from '../constants';
import { filterChannelsWithActualCategory } from './tvGuide';
import { formatISO, isAfter, isBefore } from 'date-fns';
import { getChannelLiveUrl } from '../helpers/channel/getChannelLiveUrl';
import { getClientConfig } from '../helpers/portal/getClientConfig';
import { getProgressTimeForEpgItem } from '../helpers/epg/getProgressTimeForEpgItem';
import { includesStrLike } from '../helpers/string/includesStrLike';
import { isChannelItemInFavorite } from '../helpers/channel/isChannelItemInFavorite';
import { isSameOrBefore } from '../helpers/datetime';
import { isSuccessResponse } from '../helpers/fetch/isSuccessResponse';
import { post } from '../helpers';

export const getSubscribedChannels = param => (dispatch, getState) => {
  post({
    url: `${getClientConfig().mwUrl}/public/channel/getSubscribedChannels`,
    body: { ...param },
    param,
    request: types.CHANNELS_SUBSCRIBED_REQUEST,
    success: types.CHANNELS_SUBSCRIBED_SUCCESS,
    failure: types.CHANNELS_SUBSCRIBED_FAILURE,
    dispatch,
    state: getState(),
  });
};

export const getSubscribedAndLockedChannels = param => (dispatch, getState) => {
  dispatch({
    type: types.CHANNELS_SUBSCRIBED_REQUEST,
    param: param,
    state: getState(),
  });
  fetch_channel_getSubscribedAndLockedChannels(dispatch, param, getState);
};

export const channel_getDefaultChannel = param => dispatch => {
  fetch_channel_getDefaultChannel(dispatch, param);
};

export const channel_getStreamUrl = param => dispatch =>
  fetch_channel_getStreamUrl(dispatch, param);

export const channel_getStartupChannel = param => dispatch => {
  fetch_channel_getStartupChannel(dispatch, param);
};

export const channel_setParam = param => dispatch => {
  dispatch({
    type: types.CHANNELS_SET_PARAM,
    param: param,
  })
}

export const channel_unPinActualSelectedChannel = () => dispatch => {
  dispatch({
    type: types.CHANNELS_UNPIN_ACTUAL_SELECTED_CHANNEL,
  })
}


// Init channel player in full player
export const channel_initChannelListWithActualEpg = (param) => (dispatch, getState) => {
  dispatch({
    type: types.CHANNEL_INIT_CHANNEL_LIST_WITH_ACTUAL_EPG_REQUEST,
    param,
  });
  const {selectedChannelId, type} = param || {};
  return fetch_channel_getSubscribedAndLockedChannels(dispatch, {type}, getState).then(
    data => {
      const channelsList = data.status === 1 && data.response;

      if (channelsList === undefined) { // není channels_id vyhlásíme paniku
        dispatch({
          type: types.CHANNEL_INIT_CHANNEL_LIST_WITH_ACTUAL_EPG_FAILURE,
          error: "label_no_channels_purchased",
        });
        return;
      }

      dispatch(fetch_epg_getUpdatedEventsV2WithoutRedux({
        timestamp: 0, // first init
        channels: channelsList.map(channelItem => channelItem.channels_id),
        from: formatISO(new Date()),
        to: formatISO(new Date()),
      })).then(
        data => {
          const actualEpgsList = data.response;
          const selectedChannelPrevProp = getState().channel.selectedItem;
          const channelListPlusEpg = channelsList.map(channelItem => ({...channelItem,
            actualEpg:
              [actualEpgsList.find(epgItem =>
                epgItem.channels_id === channelItem.channels_id
                && isSameOrBefore(new Date(epgItem.start), new Date())
                && isAfter(new Date(epgItem.end), new Date())
              )]
              .map(actualEpg => ({...actualEpg,
                progressTime: getProgressTimeForEpgItem(actualEpg),
              }))[0],
            isSelected: (selectedChannelId !== undefined && selectedChannelId === channelItem.channels_id),
            isFavorite: isChannelItemInFavorite(channelItem),
            playerUrl: getChannelLiveUrl(channelItem, type === "radio" ? "radio" : "tv"),
          }))
          .map(channelItem => ({...channelItem,
            actualEpgIsLoaded: (channelItem.actualEpg !== undefined),
          }));

          dispatch({
            type: types.CHANNEL_INIT_CHANNEL_LIST_WITH_ACTUAL_EPG_SUCCESS,
            channelListPlusEpg,
            ...(selectedChannelPrevProp) ? {} : {selectedItem: channelListPlusEpg[0]}, // jestliže máme již zvolený kanál tak necháme, jinak přepneme na první
          });
        },
        error => {console.error("CHANNEL_INIT_CHANNEL_LIST_WITH_ACTUAL_EPG_FAILURE", {error})},
      );
    },

  error => dispatch({type: types.CHANNEL_INIT_CHANNEL_LIST_WITH_ACTUAL_EPG_FAILURE, error})
  );
};

export const channel_recheckIfAllChannelHasValidEpg = () => (dispatch, getState) => {
  const {channel: {
    actualUsedItems: channelItems,
    selectedItem: selectedChannelItem,
  }} = getState();

  const channelItemsForReloadingEpg = channelItems.filter(channelItem =>
    !channelItem.actualEpg // nemá nastavený epg
    || isBefore(new Date(channelItem.actualEpg.end), new Date()) // pořad již skončil
  );

  (channelItemsForReloadingEpg.length === 0)
    ? dispatch({ // jen pošleme aktualizovaný čas u elementů
        type: types.CHANNEL_UPDATE_EPG_IN_ACTUAL_USED_CHANNEL_LIST,
        channelItems: getChannelEpgItemWithActualProgressTime(channelItems),
      })
    : dispatch(fetch_epg_getUpdatedEventsV2({ // musíme si aktualizovat seznam epg
      timestamp: 0, // first init
      channels: channelItemsForReloadingEpg.map(channelItem => channelItem.channels_id),
      from: formatISO(new Date()),
      to: formatISO(new Date()),
    })).then(
      updatedEpgItemsResponse => {
        if(!isSuccessResponse(updatedEpgItemsResponse)) return;
        const updatedEpgItems = updatedEpgItemsResponse.response;

        const channelItemsForReducer = channelItems.map(channelItem => ({...channelItem,
          actualEpg:
            updatedEpgItems.find(epgItem => (
              epgItem.channels_id === channelItem.channels_id
              && isSameOrBefore(new Date(epgItem.start), new Date())
              && isAfter(new Date(epgItem.end), new Date())
            ))
            || channelItem.actualEpg,
          isSelected: selectedChannelItem?.channels_id === channelItem.channels_id,
        }))
        .map(channelItem => ({...channelItem,
          actualEpg: {...channelItem.actualEpg,
            progressTime: getProgressTimeForEpgItem(channelItem.actualEpg),
          },
        }));

        dispatch({
            type: types.CHANNEL_UPDATE_EPG_IN_ACTUAL_USED_CHANNEL_LIST,
            channelItems: channelItemsForReducer,
          });
      },
      error => {console.error("CHANNEL_UPDATE_EPG_IN_ACTUAL_USED_CHANNEL_LIST", {error})}
    );
}
const getChannelEpgItemWithActualProgressTime = (channelsList) =>(
  channelsList.map(channelItem => ({...channelItem,
    actualEpg: {...channelItem.actualEpg,
      progressTime: getProgressTimeForEpgItem(channelItem.actualEpg),
    },
  })));


const fetch_channel_getSubscribedAndLockedChannels = (dispatch, param, getState) => post({
    url: `${getClientConfig().mwUrl}/public/channel/getSubscribedAndLockedChannels`,
    body: { ...param },
    success: types.CHANNELS_SUBSCRIBED_SUCCESS,
    failure: types.CHANNELS_SUBSCRIBED_FAILURE,
    dispatch,
    state: getState(),
    param,
});


export const channel_updateSearch = searchText => (dispatch, getState) => {
  const { channel: {
    items: channelItems,
    selectedCategory,
  }} = getState();
  dispatch({
    type: types.CHANNEL_UPDATE_SEARCH,
    actualUsedItems: getChannelItemsWithApplyAllFilter({channelItems, searchText, selectedCategory}),
    searchText,
  });
}

export const channel_reset = () => dispatch => {
  dispatch({
    type: types.CHANNEL_RESET,
  })
}

export const setChannelDateCursors = params => dispatch => {
  dispatch({
    type: types.CHANNEL_DATE_CURSORS,
    params,
  })
}

export const channelCategory_getChannelCategories = param => dispatch => {
  dispatch({
    type: types.CHANNEL_CATEGORY__GET_CHANNEL_CATEGORIES_REQUEST,
    param: param,
  })
  post({
    url: `${getClientConfig().mwUrl}/public/channelCategory/getChannelCategories`,
    body: { ...param },
    success: types.CHANNEL_CATEGORY__GET_CHANNEL_CATEGORIES_SUCCESS,
    failure: types.CHANNEL_CATEGORY__GET_CHANNEL_CATEGORIES_FAILURE,
    dispatch,
  })
}
export const channelCategory_setChannelCategory = selectedCategory => (dispatch, getState) => {
  const {
    channel: {
      items: channelItems,
      searchText,
    },
    tvGuide: {fletDesingItems},
  } = getState();

  dispatch({
    type: types.CHANNEL_CATEGORY__SET_CHANNEL_CATEGORY,
    selectedCategory,
    actualUsedItems: getChannelItemsWithApplyAllFilter({channelItems, searchText, selectedCategory}),
    fletDesingItemsWithFilters: filterChannelsWithActualCategory(fletDesingItems, selectedCategory),
  });
}

export const getChannelItemsWithApplyAllFilter = ({channelItems, searchText, selectedCategory}) => (
  channelItems
    .filter(channelItem =>
      searchText === ""
      || includesStrLike(channelItem.channels_name, searchText)
      || includesStrLike(channelItem?.actualEpg?.title, searchText)
    )
    .filter(channelItem =>
      !selectedCategory
      || channelItem.channels_categories.find(catItemId => catItemId === selectedCategory.channels_categories_id)
    )
    .sort((channelItemA, channelItemB) => channelItemA.channels_order - channelItemB.channels_order)
);


export const channel_settingsGetView = () => async(dispatch) => {
  dispatch({type: types.CHANNEL_SETTINGS_GET_VIEW_REQUEST});
  const param = {allDevices: true, mcastOnly: true, bcastOnly: true}
  const channelsResponse = await fetch_channel_getDefaultChannel(dispatch, param);

  if(!isSuccessResponse(channelsResponse)) {
    dispatch({type: types.CHANNEL_SETTINGS_GET_VIEW_FAILURE});
    return;
  }

  dispatch({
    type: types.CHANNEL_SETTINGS_GET_VIEW_SUCCESS,
    channelItems: channelsResponse.response.map(channelItem => ({...channelItem,
      isFavorite: isChannelItemInFavorite(channelItem),
    })),
  });
}

export const channel_settingsViewAddChannelItemToFavorite = param => async(dispatch) => {
  dispatch({type: types.CHANNEL_SETTINGS_ADD_CHANNEL_TO_FAVORITE_REQUEST});
  const responseOfFetchToAddFavorite = await fetch_profile_addFavoriteChannel(dispatch, param);

  if(!isSuccessResponse(responseOfFetchToAddFavorite)) {
    await dispatch({type: types.CHANNEL_SETTINGS_ADD_CHANNEL_TO_FAVORITE_FAILURE});
    await dispatch(channel_settingsGetView()); // refresh of actual table view
    return;
  }

  dispatch({
    type: types.CHANNEL_SETTINGS_ADD_CHANNEL_TO_FAVORITE_SUCCESS,
    idsOfFavoriteChannelItems: responseOfFetchToAddFavorite.response,
  });
}

export const channel_settingsViewRemoveChannelItemFromFavorite = param => async(dispatch) => {
  dispatch({type: types.CHANNEL_SETTINGS_REMOVE_CHANNEL_FROM_FAVORITE_REQUEST});
  const responseOfRemoveChannelFromFavorire = await fetch_profile_removeFavoriteChannel(dispatch, param);

  if(!isSuccessResponse(responseOfRemoveChannelFromFavorire)) {
    await dispatch({type: types.CHANNEL_SETTINGS_REMOVE_CHANNEL_FROM_FAVORITE_FAILURE});
    await dispatch(channel_settingsGetView()); // refresh of actual table view
    return;
  }

  dispatch({
    type: types.CHANNEL_SETTINGS_REMOVE_CHANNEL_FROM_FAVORITE_SUCCESS,
    idsOfFavoriteChannelItems: responseOfRemoveChannelFromFavorire.response,
  });
}

export const channel_playerViewAddChannelItemToFavorite = param => async(dispatch) => {
  dispatch({type: types.CHANNEL_PLAYER_ADD_CHANNEL_TO_FAVORITE_REQUEST});
  const responseOfFetchToAddFavorite = await fetch_profile_addFavoriteChannel(dispatch, param);

  if(!isSuccessResponse(responseOfFetchToAddFavorite)) {
    await dispatch({type: types.CHANNEL_PLAYER_ADD_CHANNEL_TO_FAVORITE_FAILURE});
    await dispatch(channel_initChannelListWithActualEpg({
      selectedChannelId: param.channelsId,
      type: "channel",
    })); // refresh of actual table view
    return;
  }
  dispatch({
    type: types.CHANNEL_PLAYER_ADD_CHANNEL_TO_FAVORITE_SUCCESS,
    idsOfFavoriteChannelItems: responseOfFetchToAddFavorite.response,
  });
};


export const channel_playerViewRemoveChannelItemFromFavorite = param => async(dispatch) => {
  dispatch({type: types.CHANNEL_PLAYER_REMOVE_CHANNEL_FROM_FAVORITE_REQUEST});
  const responseOfRemoveChannelFromFavorire = await fetch_profile_removeFavoriteChannel(dispatch, param);

  if(!isSuccessResponse(responseOfRemoveChannelFromFavorire)) {
    await dispatch({type: types.CHANNEL_PLAYER_REMOVE_CHANNEL_FROM_FAVORITE_FAILURE});
    await dispatch(channel_initChannelListWithActualEpg({
      selectedChannelId: param.channelsId,
      type: "channel",
    })); // refresh of actual table view
    return;
  }

  dispatch({
    type: types.CHANNEL_PLAYER_REMOVE_CHANNEL_FROM_FAVORITE_SUCCESS,
    idsOfFavoriteChannelItems: responseOfRemoveChannelFromFavorire.response,
  });
};

export const channel_RollingDesign = ({type}) => async(dispatch, getState) => {
  dispatch({
    type: types.CHANNEL_ROLLING_REQUEST,
  });
  return fetch_channel_getSubscribedAndLockedChannels(dispatch, {type}, getState).then(
    data => {
      const channelsList = data.status === 1 && data.response;

      if (channelsList === undefined) {
        dispatch({
          type: types.CHANNEL_ROLLING_FAILURE,
          error: "label_no_channels_purchased",
        });
        return;
      }

      dispatch(fetch_epg_getUpdatedEventsV2WithoutRedux({
        timestamp: 0, // first init
        channels: channelsList.map(channelItem => channelItem.channels_id),
        from: formatISO(new Date()),
        to: formatISO(new Date()),
      })).then(
        data => {
          const actualEpgsList = data.response;
          const channelListPlusEpg = channelsList.map(channelItem => ({...channelItem,
            actualEpg:
              [actualEpgsList.find(epgItem =>
                epgItem.channels_id === channelItem.channels_id
                && isSameOrBefore(new Date(epgItem.start), new Date())
                && isAfter(new Date(epgItem.end), new Date())
              )]
              .map(actualEpg => ({...actualEpg,
                progressTime: getProgressTimeForEpgItem(actualEpg),
              }))[0],
          }))
          .map(channelItem => ({...channelItem,
            actualEpgIsLoaded: (channelItem.actualEpg !== undefined),
            playerUrl: getChannelLiveUrl(channelItem, type)
          }));

          const channelRows = (arr, size) => {
            const arrayOfObjects = [];
            let i = 0;
            while(arr.length > 0) {
              let obj = {};
              obj["rowNumber"] = i;
              obj["items"] = arr.splice(0, size);
              arrayOfObjects.push(obj);
              i++;
            }
            return arrayOfObjects
          }

          const rollingDesignItems = channelRows(channelListPlusEpg, 5);
          dispatch({
            type: types.CHANNEL_ROLLING_SUCCESS,
            rollingDesignItems,
          });
        },
        error => {console.error("CHANNEL_ROLLING_FAILURE", {error})},
      );
    },
    error => dispatch({type: types.CHANNEL_ROLLING_FAILURE, error})
  );
}
