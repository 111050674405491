

export const deleteAllEmptyAndUndefinedItemInJson = (json = {}) => {
  Object.keys(json).forEach(function(k) {
    if (
      json[k] === undefined ||
      json[k] === ''
    ) {
      delete json[k]
    }
  })
  return json;
}
