import { actionTypes as types } from '../constants';
import { getClientConfig } from '../helpers/portal/getClientConfig';
import { device_getData } from './device';
import { fetch_channel_getStartupChannel } from '../fetchs';
import { fetch_vendor_getData } from '../fetchs';
import { getEpgItemPlayerUrl } from '../helpers/epg/getEpgItemPlayerUrl';


export const user_resetState = () => dispatch => {
  dispatch({ type: types.USER_RESET_STATE });
}

export const user_setSelectedProfile = (profilesId) => async(dispatch) => {
  dispatch({type: types.USER_SET_SELECTED_PROFILE_REQUEST});

  const specialHeaders = { // added authorization info
    profilesId: btoa(profilesId),
  };

  Promise.all([
    fetch_vendor_getData(dispatch,
      {  // body param
        vendorsId: getClientConfig().vendorId,
      }, specialHeaders
    ),
    fetch_channel_getStartupChannel(dispatch, {}, specialHeaders),
  ]).then(
    ([vendorData, startupChannelData]) => {
      // máme aktualizovaný vendor, můžeme ho přihlásit
      dispatch({
        type: types.USER_SET_SELECTED_PROFILE_SUCCESS,
        profilesId: profilesId,
        vendorData: vendorData,
        ...( // není nějaký preferovaný kanál na spuštění -> spustíme
          startupChannelData.status === 1
          && startupChannelData.response?.channels_id
        ) ? {
          urlRedirectAfterLogin: getEpgItemPlayerUrl(
            {...startupChannelData.response,
              isNowPlaying: true
            }, "tv"
          ),
        } : {},
      });
      dispatch(device_getData({profilesId}));
    },
    error => {
      console.log("user_setSelectedProfile", {cause: error});
      dispatch({type: types.USER_SET_SELECTED_PROFILE_FAILURE});
    }
  )
}

export const user_switchOfCurrentProfile = () => (dispatch) => {
  dispatch({
    // type: types.USER_SET_SELECTED_PROFILE_SUCCESS,
    type: types.USER_SET_SELECTED_PROFILE_FAILURE,
    // profileId: null,
  });
}


export const user_setParam = (param) => dispatch => {
  dispatch({
    type: types.USER_SET_PARAM,
    param: param,
  });
}
